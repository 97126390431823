Megaleilao.behavior.fauxradio = (function() {
	var behavior = function() {
		var $inputs = $('.pcs-fauxradio input:not(.fauxradio)');
		$inputs.addClass('fauxradio').after('<span class="pcs-fauxradio"></span>').hide();

		var $checkboxes = $('.pcs-fauxcheckbox input:not(.fauxcheckbox)');
		$checkboxes.addClass('fauxcheckbox').after('<span class="checkbox"></span>').hide();

		$checkboxes.click(function(e) {
			$(this).siblings('.checkbox').toggleClass('active');
		});
	};

	return {
		mobile : behavior,
		tablet : behavior,
		desktop : behavior
	};
}());