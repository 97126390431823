Megaleilao = Megaleilao || {};
Megaleilao.Popup = (function($) {

	var $popup 	 = $('<div role="popup" class="popup" />'),
		$wrapper = $('<div class="popup-wapper" />'),
		$title 	 = $('<div class="popup-title"><h2></h2> <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button></div>'),
		$content = $('<div class="popup-content" />'),
		$overlay = $('<div class="popup-overlay" />');

	var content = '', self = {
		content : function(content) {
			$content.html(content);
			return this;
		},
		title : function(title) {
			$title.children('h2').html(title);
			return this;
		},
		open : function() {
			if (!$popup.closest('body').size()) {
				$popup.prependTo('body');
				$popup.css({ 'display' : 'block' });
				$('body').css({ overflow: 'hidden'});
			}

			$(window).trigger('resize').trigger('resize'); // ugly but needed for correct widths

			return this;
		},
		close : function() {
			if ($popup.closest('body').size()) {
				$popup.detach();
				$('body').css({ overflow: 'inherit'});
			}
			return this;
		}
	};

	$(window).on('resize', function() {
		var mleft, mtop;
		mleft = Math.round($('body').width() / 2);
		mleft -= Math.round($wrapper.width() / 2);

		mtop = Math.round($(window).height() / 2);
		mtop -= Math.round($wrapper.height() / 2);

		$wrapper.css({
			marginLeft : mleft,
			marginTop : mtop
		});
	}).trigger('resize');

	$popup.append($wrapper.append($title).append($content))
		  .append($overlay);

	$(document).on('click', '.popup .popup-title .close', function() {
		self.close();
	});

	$(document).ajaxComplete(function(e, request, o) {
		var $form = $(e.target) || false;
		var response = request.responseJSON || {};
		if ($form && $form.attr('data-ajax') == 'popup') {
			self.title($form.attr('data-popup-title'));
			self.content(response.mensagem);
			self.open();
		}
	});

	return self;

}(jQuery));
