Megaleilao.behavior.checkout = (function() {
	var cardsAccepted = [
		{
			name: 'diners',
			value: 'DINERS',
			pattern: /^(36|38|30[0-5])/,
			cardNumberGrouping: [4, 6, 4],
			cardNumberLength: [14],
			cvcLength: 3
		},
		{
			name: 'visa',
			value: 'VISA',
			pattern: /^4/,
			cardNumberGrouping: [4, 4, 4, 4],
			cardNumberLength: [13, 14, 15, 16],
			cvcLength: 3
		},
		{
			name: 'mastercard',
			value: 'MASTERCARD',
			pattern: '^(5[1-5]\\d*|2(22[1-9]\\d*|2[3-9]\\d*|[3-6]\\d*|7[0-1]\\d*|720\\d*))$',
			cardNumberGrouping: [4, 4, 4, 4],
			cardNumberLength: [16],
			cvcLength: 3,
		}
	];

	if (!String.prototype.repeat) {
		String.prototype.repeat= function(n){
			n= n || 1;
			return Array(n+1).join(this);
		}
	}

	var makeMaskString = function(cardNumberGrouping) {
		var mask = [];
		$.each(cardNumberGrouping, function() {
			mask.push('9'.repeat(this));
		});
		return mask.join(' ');
	}

	return {
		load : function() {
			$('#collapseAddress').collapse('hide');
			$('#checkout-shipping button.continuar').addClass('disabled');

			if ($('#checkout-payment .option-cartao').is(':checked')) {
				$('.data-cart').show();
			}

			$(document).on('keyup blur', '#cc_ccno', function(e) {
				var $el = $(this), value = $el.val();

				$.each(cardsAccepted, function() {
					var card = this;
					if (value.match(card.pattern) && $el.data('matched') != card.name) {
						$('.icon.card').removeClass('active');
						$('.icon.card.' + card.name).addClass('active');
						$('#cc_cctype').val(card.value);
						$('#cc_ccno').setMask(makeMaskString(card.cardNumberGrouping));
						$('#cc_cvv2').attr('maxlength', card.cvcLength);
						$el.data('matched', card.name);
						return false; //stop here
					}
				});

			});

			$(document).on('click', '#checkout-cart .auction .select', function() {
				$(this).closest('.auction').toggleClass('selected');

				// validation
				var $selected = $('#checkout-cart .auction.selected');
				if ($selected.size() > 3) {
					var message = {
						titulo_mensagem : 'Você pode selecionar até 3 leilões.',
						mensagem : 'Você pode selecionar até 3 leilões para criação do pedido. Para selecionar um outro leilões desmarque um leilão já selecionado.'
					};

					Megaleilao.Notification.addNotification(message);
					Megaleilao.Notification.show();
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Ops! Máximo de 3 leilões no pacote!'
					});
					Megaleilao.Notification.showLastNotification();
					$(this).closest('.auction').toggleClass('selected');
					return;
				}

				var $input = $(this).find('input');
				$input.prop('checked', !$input.prop('checked'));
				$(this).find('span').text($input.prop('checked') ? 'SELECIONADO' : 'SELECIONAR');

				var subtotal = 0.0;
				$('.price .value', $selected).each(function() {
					var value = $(this).text();
					value = parseFloat(value.replace('R$ ', '').replace(/\./, '').replace(',', '.'));
					subtotal += value;
				});
				$('#checkout-cart .subtotal-items .text-value').text('R$ ' + Megaleilao.number_format(subtotal, 2, ',', '.'));
			});
			$(document).on('click', '#checkout-cart button', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				var $form = $(this).closest('form');
				
				if ($form.find('input[type=checkbox]:checked').size() > 0) {
					$form.submit();
				} else {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Você precisa selecionar um leilão.'
					});
					Megaleilao.Notification.show();
				}
			});
			$(document).on('click', '#checkout-shipping .table .select', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				
				var $input = $(this).find('input'),
					$auction = $(this).closest('tr.auction'),
					data = {cod_agrupamento: $('input[name=cod_agrupamento]').val(), 
							id_address: $input.val()};
				
				$(this).closest('table').find('.selected .select span').text('SELECIONAR');
				$(this).closest('tr').siblings('tr.selected').removeClass('selected');
				$(this).closest('tr').toggleClass('selected');
				$input.prop('checked', !$input.prop('checked'));
				$(this).find('span').text($input.prop('checked') ? 'SELECIONADO' : 'SELECIONAR');
				
				if ($input.prop('checked')) {
					$('#shippingAddress').val(
						$auction.find('.address').text().trim() + ',' +
						$auction.find('.city').text().trim() + ',' +
						$auction.find('.state').text().trim() + ',' +
						$auction.find('.zip').text().trim()
					);
					$.ajax({
						url : $('base').attr('href')+'checkout/shippingMethod',
						dataType : 'json',
						data: data,
						type : 'post',
						success : function(json) {
							var entrega = json.dados_entrega, formas, fpg, entregaDiff,
								$div = $('.formas-pagamento .display-here');
								
							$('.formas-pagamento-titulo').show();
							$('.formas-pagamento').show();
							$div.html('');
						   
							if (entrega.cep_valido) {
								if (entrega.is_entregas_disponiveis) {
									formas = entrega.formasEntregasDisponiveis;
									$.each(formas, function(i, data) {
										entregaDiff = data.html_entrega_diferenciada;
										if (entregaDiff == null) {
											entregaDiff = '';
										}
										fpg = '<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 shipping-method">' +
												'<div class="row">' +
													'<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">' +
														'<img class="img-responsive" src="' + data.img_servico + '" alt="' + data.dsc_forma_entrega + '" />' +
													'</div>' +
													'<div class="col-xs-12 col-sm-12 col-md-3 col-lg-5 shipping-name">' +
														'<h3>'+data.dsc_forma_entrega+'</h3>' +
													'</div>' +
													'<div class="col-xs-12 col-sm-12 col-md-3 col-lg-5 shipping-estimate">' +
														'<p><strong>Previsão de entrega:</strong>' +
														' <span class="text-red prazo-entrega">'+data.num_prazo_frete+'</span>' +
														' dias úteis após confirmação do pagamento.</p>' +
													'</div>' +
													'<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 shipping-price">' +
														'<div class="title">' +
															'Valor:' +
														'</div>' +
														'<div class="value text-value">' +
															'<span class="valor-frete">R$' + data.valor_frete + '</span>' +
														'</div>' +
													'</div>' +
													'<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 shipping-selector">' +
														'<div class="select">' +
															'<input type="radio" name="num_servico" value="'+data.num_servico+'">' +
															'<span>SELECIONAR</span>' +
														'</div>' +
													'</div>' +
													((entregaDiff) ? '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 shipping-diff">'+entregaDiff+'</div>' : '') +
												'</div>' +
												// '<div class="row">' +
												//     '<div class="col-xs-12">' +
												//         '<div class="radio">' +
												//             '<label>' +
												//                 '<input type="radio" name="num_servico" value="'+data.num_servico+'">' +
												//                 '<span></span>' +
												//             '</label>' +
												//         '</div>' +
												//     '</div>' +
												// '</div>' + 
											  '</div>';
										$div.append(fpg);
										Megaleilao.animateScroll('.formas-pagamento-titulo');
									});
								} else {
									fpg = '<div>Não existem formas de entrega disponíveis para este endereço.</div>';
									$div.append(fpg);
								}
							} else {
								fpg = '<div>Não fazemos entrega para o CEP especificado em seu cadastro. Favor verificar <a href="'+$('base').attr('href')+'user/edit">aqui</a> se você preencheu seu cadastro corretamente.</div>';
								$div.append(fpg);
							}
						}
					});
				} else {
					$('.formas-pagamento-titulo').hide();
					$('.formas-pagamento').hide();
				}
			});
			$(document).on('click', '.shipping-method', function(e) {
				e.preventDefault();
				if ($(this).is('.selected')) {
					return false;
				}

				$(this).siblings('.selected').removeClass('selected');
				$(this).toggleClass('selected');
				var numServico = $(this).find('[name="num_servico"]');
				numServico.prop('checked', !numServico.prop('checked'));
				$('#checkout-shipping button.continuar').removeClass('disabled');
				if(Megaleilao.mobile) 
				{
					Megaleilao.animateScroll('.continuar');
				}
			});
			var codMunicipioAux='';
			$(document).on('focusout', '#checkout-shipping input[name=zip]', function() {
				var self = $(this);
				var url = $('base').attr('href') + '/ajax/address';
				$.get(url, { cep: self.val() }).done(function(result){
					if (result.resultado == '1') {
						$('input[name=address]').val(result.logradouro);
						$('input[name=neighborhood]').val(result.bairro);
						$('select[name=state]').val(result.uf);
						$('select[name=state]').trigger('change');
						codMunicipioAux = result.codMunicipio;
						//setTimeout(function() {$('select[name=cod_municipio]').val(result.codMunicipio)}, '1000');
						
					} else {
						$('select[name=state]').val('');
						$('select[name=cod_municipio]').val('');
					}
				});
			});
			$(document).on('change', '#checkout-shipping select[name=state]', function() {
				$.ajax({
					url : $('base').attr('href')+"user/get_cidades/"+$('select[name=state]').find('option:selected').val(),
					dataType : 'json',	
					type : 'get',
					success : function(json) {
						var options_municipio = '<option value="0">Selecione</option>';
						$.each(json, function (i) {
							options_municipio += '<option value="'+i+'">'+json[i]+'</option>';
						});
						$('select[name=cod_municipio]').html(options_municipio);
						
						if(codMunicipioAux)
						{
							$('select[name=cod_municipio]').val(codMunicipioAux);	
						}
						
					}
				});
			});
			$(document).on('click', '#checkout-shipping .salvar-endereco', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				
				var div = $(this).closest('.panel-body'), $form, data;
				div.wrap('<form>');
				$form = div.closest('form');
				data = $form.serialize();
					
				$.ajax({
					url : $('base').attr('href')+'cart/saveAddress',
					dataType : 'json',
					data: data,
					type : 'post',
					success : function(json) {
						if (json.tipo_retorno == 'erro') {
							Megaleilao.Notification.setError({
								titulo_mensagem : 'Corrija o formulário',
								mensagem : json.mensagem
							});
							Megaleilao.Notification.show();
						} else {
							var tr = '<td class="hidden-lg"><a class="select-unchecked"></a></td>' +
									 '<td class="zip text">'+json.zip+'</td>' +
									 '<td class="address text">'+json.address+'</td>' +
									 '<td class="text">'+json.number+'</td>' +
									 '<td class="hidden-xs text">'+json.comp+'</td>' +
									 '<td class="hidden-xs text">'+json.neighborhood+'</td>' +                                             
									 '<td class="hidden-xs city text">'+json.city+'</td>' +
									 '<td class="hidden-xs state text">'+json.state+'</td>' +
									 '<td><div class="select">' +
										'<input type="radio" name="endereco_id" value="'+json.id_address+'">' +
										'<span>SELECIONAR</span>' +
									 '</div></td>' +
									 '<td class="hidden-lg"><a class="details"></a></td>';

							Megaleilao.Notification.addNotification({
								titulo_mensagem : 'Endereço adicionado com sucesso.'
							});
							Megaleilao.Notification.show();
							$('.table tbody').append('<tr class="auction">'+tr+'</tr>');
							$('#collapseAddress').collapse('hide');
							$form.trigger('reset');
							div.unwrap();
						}
					}
				});
			});
			$(document).on('click', '#checkout-shipping button.continuar', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();

				if ($(this).is('.disabled')) {
					return false;
				}
				
				if ($('.auction .selected').size()) {
					var $form = $('[id=checkoutShipping]'),
						$shipping = $('.formas-pagamento').find('input[type=radio]:checked');
					
					if ($shipping.size() > 0) {
						$('#valorFrete').val($shipping.parent('label').find('.valor-frete').text());
						$('#prazoEntrega').val($shipping.parent('label').find('.prazo-entrega').text());
						$form.submit();
					} else {
						Megaleilao.Notification.setError({
							titulo_mensagem : 'Você precisa selecionar uma forma de entrega.'
						});
						Megaleilao.Notification.show();
					}
				} else {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Você precisa selecionar um endereço de entrega.'
					});
					Megaleilao.Notification.show();
				}
			});
//			$(document).on('click', '#checkout-payment .option-cartao', function() {
//				if ($('#flg_controle_form').val() == 0) {
//					$('#flg_controle_form').val(1);
//				}
//				$('#cc_cctype').val($(this).val());
//				$('.form-cartao').show('slow');
//				$('.form-boleto').hide('slow');
//			});
//			$(document).on('click', '#checkout-payment .option-boleto', function() {
//				$('.form-boleto').show('slow');
//				$('.form-cartao').hide('slow');
//			});
			$(document).on('click', '#checkout-payment .finalizar-compra', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				
				var self = $(this),
					form = $(this).closest('form'),
					data = form.serialize();

					if (self.is('[disabled]')) { return false; }
					switch (form.find('input[name=metodoPagamento]:checked').val()) {
					case '1': // boleto
						if ($('.option-boleto').size()) {
							self.attr('disabled', true);
							self.addClass('btn-primary');

							$.ajax({
								url	 : $('base').attr('href')+'cart/paymentOrder',
								data	 : data,
								type	 : 'post',
								dataType : 'json',
								success: function(json) {
									if (json.order && json.agrupamento) {
										window.location.href = $('base').attr('href')+'checkout/finish';
									} else {
										Megaleilao.Notification.setError({
											titulo_mensagem : 'OCORREU UM ERRO INESPERADO!'
										});
										Megaleilao.Notification.show();
										self.removeClass('btn-primary');
										self.attr('disabled', false);
									}
								},
								error: function(ajax) { 
									Megaleilao.Notification.setError({
										titulo_mensagem : $(ajax.responseText).text()
									});
									Megaleilao.Notification.show();
									self.removeClass('btn-primary');
									self.attr('disabled', false);
								} 
							});
						}
					break;
					case '2': // cartao
						if (check_cc_payment_form()) {
							self.attr('disabled', true);
							self.addClass('btn-primary');

							$.ajax({
								url : $('base').attr('href')+'cart/paymentOrder',
								data: data,
								type : 'post',
								dataType : 'json',
								success: function(json) {
									if (json.order && json.agrupamento) {
										window.location.href = $('base').attr('href')+'checkout/finish';
									} else {
										Megaleilao.Notification.setError({
											titulo_mensagem : 'OCORREU UM ERRO INESPERADO!'
										});
										Megaleilao.Notification.show();
										self.removeClass('btn-primary');
										self.attr('disabled', false);
									}
								},
								error: function(ajax) { 
									self.attr('disabled', false);
									self.removeClass('btn-primary');
									Megaleilao.Notification.setError({
										titulo_mensagem : $(ajax.responseText).text()
									});
									Megaleilao.Notification.show();
								} 
							});
						}
					break;
				}
			});
					
			$(document).on('click', '#checkout-shipping .auction tbody > tr', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				
				if (Megaleilao.mobile) {
					var unchecked = !$(this).find('.select-checked').size();
					
					$(this).find('.select').trigger('click');
					$('.select-checked').addClass('select-unchecked')
										.removeClass('select-checked');
					if (unchecked) {
						$(this).find('.select-unchecked')
							   .addClass('select-checked')
							   .removeClass('select-unchecked');
					}
				}
			});
					
			$(document).on('click', '#checkout-shipping .details', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				var address = [], $tr = $(this).closest('tr'), j = 0;
				
				$tr.find('td.text').each(function(i, td) {
					var text = $(td).text().trim();
					
					if (text.length > 0) {
						address[j] = text;
						j++;
					}
				});
				address[j] = $tr.find('.zip').text().trim();
				Megaleilao.Popup.content(address.join(", ")).title('Endereço de Entrega').open();
			});

			$(document).on('click', '#checkout-payment input[name=metodoPagamento]', function() {
				if ($(this).val() == 2) {
					$('.data-cart').show();
					$('.icon-cart .icon.card').removeClass('active');
				} else {
					$('.data-cart').hide();
					$('.icon-cart .icon.card').addClass('active');
				}
				if (Megaleilao.mobile) {
					$('.icon-cart').each(function(i, div) {
						$(div).closest('label').css('background', 'lightgray');
					});
					$(this).closest('label').css('background', 'darkgray');
				}
				$('.finalizar-compra').removeClass('disabled');
			});

			function check_cc_payment_form() {
				var cc_name = $('#cc_name'),
				cc_cctype = $('#cc_cctype'),
				cc_ccno = $('#cc_ccno'),
				cc_ccexpm = $('#cc_ccexpm'),
				cc_ccexpy = $('#cc_ccexpy'),
				cc_psjuros = $('#cc_psjuros'),
				cc_cpftitular = $('#cc_cpftitular');

				if (cc_name.val() == '') {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, preencha o Nome do Titular.'
					});
					Megaleilao.Notification.show();
					cc_name.focus();
					return false;
				}

				if (!validaCpf(cc_cpftitular.val())) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'CPF inválido.'
					});
					Megaleilao.Notification.show();
					cc_cpftitular.focus();
					return false;
				}

				if (cc_cctype.get(0).selectedIndex == 0) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, escolha a bandeira do cartão.'
					});
					Megaleilao.Notification.show();
					cc_cctype.focus();
					return false;
				}				
				var card_nro = cc_ccno.val();
				var trimmed_cc_ccno = card_nro.replace(/ /g,'');
				
				if (isNaN(trimmed_cc_ccno) || trimmed_cc_ccno == '' || !validateCreditCard(trimmed_cc_ccno)) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, digite um número de cartão crédito válido sem espaços nem traços.'
					});
					Megaleilao.Notification.show();
					cc_ccno.focus();
					cc_ccno.select();
					return false;
				}

				if ($('#cc_cvv2').val() == '' || isNaN($('#cc_cvv2').val()) || $('#cc_cvv2').val().length > 4) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, digite o código de segurança. Geralmente estes três ou quatro dígitos são mostrados no verso do cartão.'
					});
					Megaleilao.Notification.show();
					$('#cc_cvv2').focus();
					$('#cc_cvv2').select();
					return false;
				}

				if (cc_ccexpm.get(0).selectedIndex == 0) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, preencha o mês de vencimento do cartão.'
					});
					Megaleilao.Notification.show();
					cc_ccexpm.focus();
					return false;
				}

				if (cc_ccexpy.get(0).selectedIndex == 0) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, preencha o ano de vencimento do cartão.'
					});
					Megaleilao.Notification.show();
					cc_ccexpy.get(0).focus();
					return false;
				}

				if (cc_psjuros.get(0).selectedIndex == 0) {
					Megaleilao.Notification.setError({
						titulo_mensagem : 'Por favor, preencha o Número de Parcelas.'
					});
					Megaleilao.Notification.show();
					cc_psjuros.focus();
					return false;
				}
				
				return true;
			}

			function validateCreditCard(number) {
				// Strip any non-digits (useful for credit card numbers with spaces and hyphens)
				var number=number.replace(/\D/g, '');

				// Set the string length and parity
				var number_length=number.length;
				var parity=number_length % 2;

				// Loop through each digit and do the maths
				var total=0;
				for (i=0; i < number_length; i++) {
					var digit=number.charAt(i);
					// Multiply alternate digits by two
					if (i % 2 == parity) {
						digit=digit * 2;
						// If the sum is two digits, add them together (in effect)
						if (digit > 9) {
							digit=digit - 9;
						}
					}
					// Total up the digits
					total = total + parseInt(digit);
				}

				// If the total mod 10 equals 0, the number is valid
				if (total % 10 == 0) {
					return true;
				} else {
					return false;
				}
			}

			// function requiresCVV2() {
			// 	var type = $('#cc_cctype').val();

			// 	if (type) {
			// 		if ($('#CCType_'+type).hasClass('requiresCVV2')) {
			// 			return true;
			// 		} else {
			// 			return false;
			// 		}
			// 	} else {
			// 		return false;
			// 	}
			// }

			// function hasIssueDate() {
			// 	var type = $('#cc_cctype').val();

			// 	if (type) {
			// 		if ($('#CCType_'+type).hasClass('hasIssueDate')) {
			// 			return true;
			// 		} else {
			// 			return false;
			// 		}
			// 	} else {
			// 		return false;
			// 	}
			// }

			// function hasIssueNo() {
			// 	var type = $('#cc_cctype').val();

			// 	if (type) {
			// 		if ($('#CCType_'+type).hasClass('hasIssueNo')) {
			// 			return true;
			// 		} else {
			// 			return false;
			// 		}
			// 	} else {
			// 		return false;
			// 	}
			// }

			// function updateCreditCardType() {
			// 	if (requiresCVV2()) {
			// 		$('.CVV2Input').show();
			// 	} else {
			// 		$('.CVV2Input').hide();
			// 	}
			// 	if (hasIssueNo()) {
			// 		$('.CCIssueNo').show();
			// 	} else {
			// 		$('.CCIssueNo').hide();
			// 	}
			// 	if(hasIssueDate()) {
			// 		$('.CCIssueDate').show();
			// 	} else {
			// 		$('.CCIssueDate').hide();
			// 	}
			// }

			function validaCpf(value) {
				value = $.trim(value);
				value = value.replace('.','');
				value = value.replace('.','');

				var cpf = value.replace('-','');
				while (cpf.length < 11) cpf = "0"+ cpf;
				var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
				var a = [];
				var b = new Number;
				var c = 11;
				for (i = 0; i < 11; i++) {
					a[i] = cpf.charAt(i);
					if (i < 9) b += (a[i] * --c);
				}
				if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11-x }
					b = 0;
				c = 11;
				for (y=0; y<10; y++) b += (a[y] * c--);
					if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }

				var retorno = true;
				if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) retorno = false;

				return retorno;
			}
			$(document).on('click', '.btn-imprime-boleto', function() {
				$('#geraBoleto').submit();
			});
			$(document).on('click', '#checkout-payment .btn-pagar-pedido-boleto', function(event) {
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				
				var self = $(this),
					form = $(this).closest('form'),
					data = form.serialize();
	
				if (self.is('[disabled]')) { return false; }
				if ($('.option-boleto').size()) {
					self.attr('disabled', true);
					self.addClass('btn-primary');

					$.ajax({
						url	 : $('base').attr('href')+'cart/paymentOrder',
						data	 : data,
						type	 : 'post',
						dataType : 'json',
						success: function(json) {
							if (json.order && json.agrupamento) {
								window.location.href = $('base').attr('href')+'checkout/finish';
							} else {
								Megaleilao.Notification.setError({
									titulo_mensagem : 'OCORREU UM ERRO INESPERADO!'
								});
								Megaleilao.Notification.show();
								self.attr('disabled', false);
							}
						},
						error: function(ajax) { 
							Megaleilao.Notification.setError({
								titulo_mensagem : $(ajax.responseText).text()
							});
							Megaleilao.Notification.show();
							self.attr('disabled', false);
						} 
					});
				}
			});
		},
		mobile : function() {
			if ($('#checkout-payment').size()) {
				var text;

				$('#endereco_fatura').collapse('hide');
				$('#endereco_entrega').collapse('hide');
				$('#frete').collapse('hide');

				$('.icon-cart').each(function(i, div) {
					var $label = $(this).siblings('label');

					$label.find('input').hide();
					$label.find('input').after($(div));
					$(div).css('display', 'block');
					$label.closest('.radio').css('text-align', 'center');
					$label.css({'width' : '100%', 'border-radius' : '5px', 'background' : 'lightgray', 'padding' : '10px'});
					if ($label.find('input').is(':checked')) {
						$label.css('background', 'darkgray');
					}
				});
				text = $('#endereco_fatura p').text();
				$('a[href=#endereco_fatura]').append('<h4><small>'+text.split(',')[0]+','+text.split(',')[1]+'</small></h4>');
				text = $('#endereco_entrega p').text();
				$('a[href=#endereco_entrega]').append('<h4><small>'+text.split(',')[0]+','+text.split(',')[1]+'</small></h4>');
				text = $('#frete p strong:first').text().replace(':', '');
				$('a[href=#frete]').append('<h4><small>'+text.split(',')[0]+'</small></h4>');
			}
		},
		desktop : function() {
			if ($('#checkout-payment').size()) {
				$('#endereco_fatura').collapse('show');
				$('#endereco_entrega').collapse('show');
				$('#frete').collapse('show');

				$('.icon-cart').each(function(i, div) {
					var $label = $(this).closest('label');

					$label.find('input').show();
					$label.after($(div));
					$(div).css('display', '-webkit-inline-box');
					$label.closest('.radio').css('text-align', 'left');
					$label.css({'width' : 'auto', 'border-radius' : '0', 'background' : 'none', 'padding' : '0px 0px 0px 20px'});
				});
				$('#accordion_endereco_fatura').find('h4').remove();
				$('#accordion_endereco_entrega').find('h4').remove();
				$('#accordion_frete').find('h4').remove();
			}
		}
	};
}());