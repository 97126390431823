Megaleilao.behavior.btnRegister = (function() {
	var $btnRegister, $usefulLinks;
	return {
		load : function() {
			$btnRegister = $('.btn-register');
			$usefulLinks = $('#useful-links');
		},
		mobile : function() {
			$('header').addClass('icons');
			if (!$usefulLinks.find('.search-action').size()) {
				$usefulLinks.children('ul').prepend($('<li />').append($('<a href="#search" title="Pesquisar" class="search-action"><i class="glyphicon glyphicon-search"></i></a>').click(function(e) {
					e.preventDefault();
					$('form#search').parent().toggleClass('hidden-xs');
				})));

				$('#logo').parent().after($usefulLinks);
			}
		},
		tablet : function() {
			Megaleilao.behavior.btnRegister.mobile();
		},
		desktop : function() {
			$usefulLinks.find('.search-action').parent().remove();
			$usefulLinks.appendTo($('#useful-links-holder'));

			$('header.icons').removeClass('icons');

			if (!$btnRegister.closest('.logged-info').size()) {
				$btnRegister.unwrap('li').appendTo('.logged-info');
				$btnRegister.removeClass('btn-sm');
			}

			var $links = $usefulLinks.find('a');
			$.each('btn btn-default btn-sm btn-block'.split(' '), function() {
				$links.removeClass(''+this);
			});

			$usefulLinks.find('li').removeClass('half-size-block');
		}
	};
}());