jQuery(function($) {

	$(document).on('click', '[data-moviment]', function(e) {
		e.preventDefault();
		Megaleilao.animateScroll($(this).data('moviment'));
	});

	$(document).on('click', '[data-image]', function(e) {
		e.preventDefault();
	});

	$(document).on('click', '[data-toggle]', function(e) {
		e.preventDefault();
		var $target = $($(this).data('toggle'));
		if ($target.size()) {
			e.stopPropagation();
			$target.toggleClass('hide');
		}
	});

	$(document).on('focusout', '[data-mask][alt="phone"]', function(){
		var phone, element;
		element = $(this);
		element.unsetMask();
		phone = element.val().replace(/\D/g, '');
		if(phone.length > 10) {
			element.setMask($.mask.masks['phone9digit'].mask);
		} else {
			element.setMask($.mask.masks['phone'].mask);
		}
	}).trigger('focusout');

	$('#attributes-filter .panel:nth-child(3) .list-group .list-group-item input').each(function() {
		var input = $(this);
		$('.ml-banner-brands .slide img[alt="'+$(this).parent().text().trim()+'"]').click(function(e) {
			input.click();
		});
	});

	$(document).on('click', '[data-select] [data-value]', function(e) {
		e.preventDefault();
		var $self = $(this).closest('[data-select]'), 
			field = $self.data('select'),
			$current = $(e.currentTarget);

		if ($(this).closest('#attributes-filter').size()) {
			Megaleilao.behavior.auctionLoader.show();
		}

		if ($current.is('.active')) {
			$current.removeClass('active');
			$(field).first().val('');
		} else {
			$self.find('.active').removeClass('active');
			$current.addClass('active');
			$(field).first().val($(this).data('value'));
		}

		$(field).first().trigger('change');
	});

	$('form#search').attr('data-ajax', 'replace').attr('action', $('base').attr('href') + 'ajax/search/');

	$('.panel .panel-heading a').prepend('<i class="glyphicon glyphicon-menu-up"></i> ');
	$('.panel .in').each(function() {
		$(this).parent().find('.panel-heading a .glyphicon-menu-up').removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
	});
	$(document).on('hide.bs.collapse show.bs.collapse', '.panel', function (e) {
		var $icon = $(this).find('.panel-heading a .glyphicon');
		$icon.toggleClass('glyphicon-menu-up', $icon.has('.glyphicon-menu-up'));
		$icon.toggleClass('glyphicon-menu-down', $icon.has('.glyphicon-menu-down'));
	});
});