Megaleilao = Megaleilao || {};
Megaleilao.Notification = (function($) {

	var inAjax = false,
		isShown = false,
		isOpen = false,
		hasMessage = false,
		isDetailed = false,
		animationTime = 100;

	var actionsAdded = {}, self = {
		hasMessage : function(hasmessage) {
			hasMessage = hasmessage;
			return this;
		},
		setInAjax : function(isAjax) {
			inAjax = isAjax;
			return this;
		},
		show : function() {
			if (inAjax || isShown) {
				return this;
			}

			$bar.stop().animate({ top : 0 });
			isShown = true;

			return this;
		},
		hide : function() {
			if (
				(Megaleilao.Modal && Megaleilao.Modal.isOpen()) || 
				$('.navbar.mobile-menu.show').size() > 0 || 
				$('#sidebar-wrapper.show').size() > 0 ||
				$notifications.find('.notification').size() > 0 ||
				hasMessage
			) {
				return this;
			}

			if (inAjax || !isShown) {
				return this;
			}

			isShown = false;

			setTimeout(function() {
				$bar.stop().animate({ top : $bar.outerHeight() * -1 });
				self.setNormal();
				self.clear();
				self.close();
			}, 500);

			return this;
		},
		open : function() {
			if (inAjax || isOpen) {
				return this;
			}

			var height = $bar.data('original-height');
				height += $notifications.outerHeight();
			$bar.height(height);

			if ($notifications.children('div').size()) {
				self.setNormal();
				self.setTitle('Notificações');
			}

			isOpen = true;

			return this;
		},
		close : function() {
			if (inAjax || !isOpen) {
				return this;
			}

			$bar.height($bar.data('original-height'));
			self.setTitle();
			isOpen = false;

			return this;
		},
		clear : function() {
			$notifications.empty();
		},
		setSuccess : function(response) {
			self.setNormal();
			self.hasMessage(true);
			$bar.addClass('notification-success');
			if (!response.titulo_mensagem) {
				response.titulo_mensagem = response.mensagem;
				delete response.mensagem;
			}

			self.setTitle(response.titulo_mensagem);
			setTimeout(function() {
				self.hide();
			}, 5000);
			return this;
		},
		setError : function(response) {
			self.setNormal();
			self.hasMessage(true);
			$bar.addClass('notification-error');

			if (response.titulo_mensagem && response.mensagem) {
				response.titulo_mensagem += ': ' + response.mensagem;
			}
			self.setTitle(response.titulo_mensagem);

			setTimeout(function() {
				self.hide();
			}, 5000);

			return this;
		},
		setNormal : function() {
			$bar.removeClass('notification-error')
				.removeClass('notification-success');
			self.hasMessage(false);
			self.setTitle();
			return this;
		},
		setTitle : function(title) {
			$title.html(title || '&nbsp;');
			return this;
		},
		showLastNotification : function() {
			var $lastNotification = $notifications.children(':first');
			var height = $bar.data('original-height');
			height += $lastNotification.height() + 10;
			$bar.height(height);
			var self = this;
			self.show();
			setTimeout(function() {
				var height = $bar.data('original-height');
				$bar.height(height);
			}, 5000);
		},
		addNotification : function(response, callback, data, putInList) {
			if (putInList === undefined) {
				putInList = true;
			}

			if (!response.titulo_mensagem) {
				console.trace();
				return this;
			}

			var $notification = $('<div class="notification" />');
			$notification.append($('<div class="notification-title" />').html(response.titulo_mensagem || ''));
			$notification.append($('<div class="notification-content" />').html(response.mensagem || ''));
			$notification.append($('<div class="notification-action" />').append($('<i class="glyphicon glyphicon-remove" />').click(function(e) {
				e.preventDefault();
				e.stopPropagation();
				$(this).closest('.notification').remove();
				Megaleilao.Notification.setNormal();
				Megaleilao.Notification.hide();
			})));

			switch(response.tipo_mensagem) {
				case 'erro' :
					if (response.showLogin) {
						var url = $('base').attr('href') + 'user/login',
							requestQty = Megaleilao.requests.length,
						    data = {};

						if (requestQty) {
							var lastRequest = Megaleilao.requests[requestQty-1][0],
								params = lastRequest.url.split('/'),
								param = params.pop(),
								action = params.pop(),
								controller = params.pop();
							data['redirect'] = controller+'::'+action+'::'+param;
						}

						$.get(url, data, function(content) {
							Megaleilao.Notification.clearActions();
							Megaleilao.Notification.addAction('back', function() {
								Megaleilao.Notification.setNormal();
								Megaleilao.Modal.goBackContent();
								Megaleilao.Notification.clearActions();
								Megaleilao.Notification.addAction('back', function() {
									Megaleilao.Modal.hide();
									Megaleilao.Notification.hide();
								}).show();
							});
							Megaleilao.Modal.show(content);
						});
					}
				break;
			}

			$notification.on('click', function(e) {
				e.preventDefault();
				e.stopPropagation();
			});

			if (putInList) {
				$notifications.prepend($notification);
			}

			switch(response.tipo_mensagem) {
				case 'erro' :
					Megaleilao.Notification.showLastNotification();
				break;
			}

			return this;
		},
		addAction : function(action, callback) {
			if (actionsAdded[action]) {
				return this;
			}

			var $el = actions[action].clone().attr('data-action', action).click(function(e) {
				e.stopPropagation();
				if (typeof callback == 'function') {
					callback.apply(this, [e]);
				}
			});

			$actions.append($el);
			actionsAdded[action] = $el;

			return this;
		},
		removeAction : function(action) {
			$actions.find('[data-action="'+action+'"]').remove();
			delete actionsAdded[action];

			return this;
		},
		clearActions : function() {
			$actions.empty();
			actionsAdded = [];

			return this;
		},
		loader : {
			show : function() {
				self.show();
				$loader.show().animate({ opacity : 1 }, 100);
			},
			hide : function() {
				$loader.animate({ opacity : 0 }, 100, function() {
					$(this).hide();	
				});
			}
		}
	};

	var $bar = $('<div role="notification-bar" class="notification-bar" />');
	var $actions = $('<div class="notification-actions" />');
	var $loader = $('<div class="notification-loader" />');
	var $title = $('<div class="notification-title" />');
	var $notifications = $('<div class="notification-holder" />');
	var actions = {
		back : $('<i class="glyphicon glyphicon-chevron-right" />')
	}

	$bar.append($actions);
	$bar.append($title);
	$bar.append($loader);

	$bar.css({ overflow: 'hidden' });
	$bar.append($notifications);

	$loader.hide().css({ opacity : 0 });

	$(function() {
		$bar.prependTo('body');
		setTimeout(function() {
			$bar.data('original-height', $bar.outerHeight());
			$bar.css({ top : $bar.outerHeight() * -1 });
		}, 200);
	});

	$bar.click(function(e) {
		e.preventDefault();

		if (Megaleilao.objSize(actionsAdded) === 1) {
			for(var i in actionsAdded) { actionsAdded[i].click(); };
		} else {
			if (!isOpen) {
				self.open();
			} else {
				self.close();
			}
		}
	});

	$(document).on('ajaxStart', function(e, request, o) {
		self.loader.show();
		self.setInAjax(true);
	}).on('ajaxStop', function(e, request, o) {
		self.setInAjax(false);
		self.loader.hide();
		self.hide();
		self.hasMessage(false);

		// var $target = $(e.target);
		// switch($target.attr('data-ajax')) {
		// 	case 'replace' :
		// 	case 'load' :
		// 		self.hide();
		// 	break;
		// }
	})
	.ajaxComplete(function(e, request, o) {
		var response = request.responseJSON || {};
		switch(response.tipo_mensagem) {
			case 'sucesso' :
				self.setSuccess(response);
				if (response.name && !response.titulo_mensagem && response.mensagem) {
					response.titulo_mensagem = response.mensagem;
					response.mensagem = response.mensagem + ': ' + response.name + '<br />Tamanho selecionado: ' + $('.bid-select-size .active a').attr('title');
				}
				self.addNotification(response, undefined, o, false);
			break;
			case 'erro' :
				self.setError(response);
				self.addNotification(response, undefined, o, false);
			break;
		}
	});

	return self;

}(jQuery));
