Megaleilao.alert = (function($) {

	var secondsToClose = 10;

	var Alert = (function(element) {
		var $element = $(element);

		this.setupEvents = function() {
			var self = this;

			$element.find('.close').on('click', function(e) {
				e.preventDefault();
				e.stopImmediatePropagation();
				$element.addClass('closed');
			});

			setTimeout(function() {
				$element.addClass('closed');
			}, (1000 * secondsToClose));
		};

		this.init = function() {
			if ($element) {
				$element.prepend('<button type="button" class="close" data-dismiss="alert" aria-label="Fechar"><span aria-hidden="true">&times;</span></button>')
			}

			this.setupEvents();
		};
	});

	$(function() {
		var $alerts = $('.alert.closable');
		$alerts.each(function() {
			var instance = new Alert(this);
			$(this).data('alert.instance', instance);
			instance.init();
		});
	})

}(jQuery));