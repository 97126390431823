Megaleilao = Megaleilao || {};
Megaleilao.WebNotification = (function($) {
	var self = {
		icon : $('base') . attr('href') + '/themes/megaleilao_mobile/assets/dist/img/notification_icon.png',

		requestPermission : function(callback) {
			return window.Notification.requestPermission(callback);
		},

		permission : function() {
			return window.Notification.permission;
		},

		hasPermission : function() {
			return this.permission() !== 'default' && 
				   this.permission() !== 'denied';
		},

		create : function(title, body, tag, callback) {
			var self = this;

			if (typeof tag == 'function') {
				callback = tag;
				tag = undefined;
			}

			if (!tag) {
				tag = 'megaleilao';
			}

			var not = new window.Notification(title, { 
				body : body,
				icon: self.icon,
				tag: tag
			});

			not.onclick = callback;

			return not;
		}
	};

	$(function() {
		var $holder = $('.web-notification');
		$holder.on('click', '.deny-permission', function(e) {
			$holder.toggleClass('hide');
			$.post('/ajax/configuration', { config : 'web-notification', value : false });
		});

		$holder.on('click', '.accept-boleto', function(e) {
			e.preventDefault();
			var $self = $(this);

			if ($self.prop('disabled')) {
				return false;
			}

			$.post($('base').attr('href') + 'ajax/configuration', { config : 'atencao-boleto', value : true });
			setTimeout(function() {
				$holder.addClass("hide");
			}, 500);
		});

		$holder.on('click', '.request-permission', function(e) {
			e.preventDefault();
			var $self = $(this);

			if ($self.prop('disabled')) {
				return false;
			}

			self.requestPermission(function(result) {
				switch(result) {
					case 'granted' :
						$self.text('Permissão Concedida');
						$.post($('base').attr('href') + 'ajax/configuration', { config : 'web-notification', value : true });
						setTimeout(function() {
							$holder.addClass("hide");
						}, 500);
					break;
					case 'denied' :
						$self.text('Tentar Novamente');
						var $tryAgain = $holder.find('.try-again');
						if ($tryAgain.hasClass('hide') && $self.text() == 'Tentar Novamente') {
							$tryAgain.toggleClass('hide');
							$self.prop('disabled', true);
						}
					break;
					default :
						$self.text('Solicitar Permissão');
					break;
				}

				$self.prop('disabled', false);
			});

			$self.prop('disabled', true).text('Aguardando Permissão');
		});

		if (!('serviceWorker' in navigator)) {
			console.debug('serviceWorker: not supported');
			return;
		} else {
			navigator.serviceWorker.register('/notification-worker.js').then(WebNotification.init).catch(function(e) {
				console.log('register: Unable to register. ' + e);
			});
		}

		if (self.permission() == 'default') {
			$holder.removeClass('hide');
		}

		WebNotification.worker.onSubscriptionChange(function(subscription) {
			var subscriptionId = subscription.subscriptionId;
			if (!subscriptionId) {
				subscriptionId = subscription.endpoint.replace('https://android.googleapis.com/gcm/send/', '');
			}
			$.ajax({
				'url'	 : $('base').attr('href') + 'ajax/configuration',
				'method' : 'post',
				'data'   : {
					'config' : 'push-registration-id',
					'value'	 : subscriptionId
				}
			});
		});

		WebNotification.worker.onReady(function() {
			WebNotification.subscribe(function(subscription) {
				var subscriptionId = subscription.subscriptionId;
	                        if (!subscriptionId) {
        	                        subscriptionId = subscription.endpoint.replace('https://android.googleapis.com/gcm/send/', '');
	                        }
				console.debug('subscribe: User subscribed. ID: ' + subscriptionId);
			}, function(e) {
				console.debug('subscribe: ' + e);
			});
		});
	});

	// self.create('Leilão Superado', 'Lance superado no leilão #10000 no valor de R$ 10,99 por usuario.');
	// var not = new window.Notification('Leilão Superado', { 
	// 	body : 'Lance superado no leilão #10000 no valor de R$ 10,99 por usuario.',
	// 	icon: 'http://php54.pentagrama/intranet/svn_sys/victor.moura/wtennis/megaleilao/themes/megaleilao_mobile/assets/dist/img/logo.png',
	// 	tag: 'megaleilao-auction-overcome'
	// });

	return self;

}(jQuery));
