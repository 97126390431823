Megaleilao.behavior.auctionLoader = (function() {
	var page = 1, xhr = null, $pcsLoader = $('<button class="btn btn-block btn-loader">{text}</button>'), doAjaxScroll;

	return {
		messages : {
			loading   : 'Carregando...',
			default   : 'Carregar mais resultados...',
			noResults : 'Não há mais resultados.'
		},

		setDefault : function() {
			Megaleilao.Notification.hide();
			$pcsLoader.text(this.messages.default);
			$pcsLoader.removeClass('loading').removeClass('no-results');
		},

		setLoading : function() {
			Megaleilao.Notification.show();
			$pcsLoader.text(this.messages.loading);
			$pcsLoader.addClass('loading').removeClass('no-results');
		},

		setNoResults : function() {
			Megaleilao.Notification.hide();
			$pcsLoader.text(this.messages.noResults);
			$pcsLoader.removeClass('loading').addClass('no-results');
		},

		load : function() {
			var page = 1, xhr = null, self = this, defaultTitle = $('head title').text();
			doAjaxScroll = function(e) {
				if ($pcsLoader.is(':visible')) {
					var currentHeight = $(window).height() + $(window).scrollTop(),
						docHeight = Megaleilao.getDocHeight(),
						type = $('body').data('offerType');

					if (currentHeight == docHeight || (e.target == $pcsLoader.get(0) && (e.type == 'click' || e.type == 'touch'))) {
						self.setLoading();

						if (xhr) {
							xhr.abort();
						}

						if ($('body').data('pages') && $('body').data('page') > $('body').data('pages')) {
							self.setNoResults();
							return;
						}

						var url = $('base').attr('href') + 'ajax/offer/' + type + '/' + Megaleilao.__lastExecutedBehavior + '/';
						if ($('body').data('url')) {
							url = $('body').data('url');
							$('body').data('url', null);
						}

						var lastAuction = $('#content > [data-auction]:last').data('auction');

						xhr = $.ajax({
							url : url,
							data : {
								'page' : $('body').data('page') ? $('body').data('page') : page,
								'last-auction' : lastAuction
							}
						}).done(function(json, status, xhr) {
							if (!json.holder) {
								json.holder = 'search';
								$('#content').attr('data-offer-holder', 'search');
							}
							if (json.pages) {
								$('body').data('pages', json.pages);
							}

							if (json.content && json.content.indexOf('Não há leilões') > -1) {
								self.setNoResults();
								return;
							}

							if (json.content && json.holder && $('body').data('page') <= $('body').data('pages')) {
								$('[data-offer-holder="'+json.holder+'"]').append(json.content);
								self.setDefault();
							} else {
								self.setNoResults();
							}

							if (json.page) {
								$('body').data('page', json.page + 1);
							}
						});
					}
				}
			};

			$(window).on('touchmove scroll', doAjaxScroll);
			$(document).on('click touch', '.btn.btn-block.btn-loader', doAjaxScroll);

			$(document).ajaxComplete(function(event, jqXHR, ajaxOptions) {
				var json = jqXHR.responseJSON;
				if (json && json.pages && json.page && json.pages >= json.page) {
					$('body').data('page', json.page + 1);
					$('body').data('pages', json.pages);
					$('body').data('url', ajaxOptions.url);
					self.setDefault();
					self.show();
				}
			});
		},
		isShown : function() {
			return $pcsLoader.closest('#content').size() > 0;
		},
		show : function() {
			var $container = $('#content');
			if ($('body').data('pages') !== undefined && $('body').data('pages') > 0) {
				$container.after($pcsLoader);
			}
		},
		tablet : function() {
			this.setDefault();
			if ($('[data-offer-holder]').size()) {
				this.show();
			}
		},
		mobile : function() {
			this.setDefault();
			if ($('[data-offer-holder]').size()) {
				this.show();
			}
		},
		desktop : function() {
			this.setDefault();
			if ($('body:not(.home) [data-offer-holder]').size()) {
				this.show();
			}
		}
	};
}());
