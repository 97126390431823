Megaleilao = Megaleilao || {};
Megaleilao.Modal = (function($) {
	var $wrapper, $content, $backContents = [], _isShow = false;

	var self = {
		backContents : [],
		holdBackContent : function() {
			this.backContents.push($content.clone(true, true));
		},
		goBackContent : function() {
			var content = this.backContents.pop();
			$content.replaceWith(content);
			$content = content;
		},
		isOpen : function() {
			return _isShow;
		},
		show : function(content, callback) {

			_isShow = true;

			if (content !== undefined) {
				$content.html(content);
			}

			$wrapper.show(0).animate({
				right : 0
			}, 500, function() {
				$('body').addClass('content-lock');

				if (typeof Megaleilao.Notification == 'object') {
					Megaleilao.Notification.clearActions().addAction('back', function() {
						Megaleilao.Modal.hide();
						Megaleilao.Notification.hide();
					}).show();
				}

				if (typeof callback == 'function') {
					callback.apply(this);
				}

				Megaleilao.executeBehavior('modal.show');
			});
		},
		hide : function(callback) {
			_isShow = false;

			$wrapper.animate({
				right : $(window).width() * -1
			}, 500, function() {
				$('body').removeClass('content-lock');

				$(this).hide(0);

				if (typeof callback == 'function') {
					callback.apply(this);
				}

				Megaleilao.executeBehavior('modal.hide');
			});

			if (typeof Megaleilao.Notification == 'object') {
				Megaleilao.Notification.removeAction('back').hide();
			}
		}
	};

	$(function() {
		$wrapper = $('<div id="modal-wrapper" class="modal-wrapper" />').appendTo('body');
		$wrapper.append('<div class="modal-content col-xs-12 col-sm-12 col-md-12 col-lg-12" />');
		$content = $wrapper.children('.modal-content');
		$wrapper.css({
			right : $(window).width() * -1
		}).hide(0);
	});

	return self;

}(jQuery));