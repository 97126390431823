Megaleilao.behavior.carousel = (function() {
	var setup = function() {
		$('[data-carousel]').each(function() {
			if (!Megaleilao.mobile && !Megaleilao.tablet && $(this).data('carouselDesktop') == false) {
				return true;
			}

			$(this).wrap('<div class="pcs-slider-wrapper" />');

			var $wrapper = $(this).closest('.pcs-slider-wrapper');

			$wrapper.attr('id', $(this).attr('id'));
			$(this).removeAttr('id');

			$wrapper.addClass($(this).attr('class'));
			$(this).removeAttr('class');

			var $slideWrapper = $('<div class="slider-wrapper" />');

			$(this).addClass('pcs-slider-wrapper-flow').append($slideWrapper);
			$(this).children('div:not(.slider-wrapper)').appendTo($slideWrapper);

			$(this).find('h4').prependTo($wrapper);

			if ($(this).data('carouselItems') && $(this).data('carouselItems') > 1) {
				var items = $(this).data('carouselItems');
				var $slides = $slideWrapper.children('div');
				var slidesNum = $slides.size();
				var actualSlide = 0;
				for(var s = Math.ceil(slidesNum / items); s > 0; s--) {
					var $div = $('<div class="pcs-slide-item" />');
					$div.appendTo($slideWrapper);
					for(var i = 0; i < items; i++) {
						if ($slides.get(actualSlide)) {
							$($slides.get(actualSlide)).appendTo($div);
						}
						actualSlide++;
					}
				}
			}

			var swipe = false;

			if ($.fn.Swipe) {
				var data = {
					slidesPerPage : 2
				};

				if ($(this).data('carouselAuto')) {
					data['auto'] = 3000;
				}

				if ($(this).data('carouselBullets')) {
					var self = this;
					data['transitionEnd'] = function(index, elem) {
						$('.actions .bullet.active', self).removeClass('active');
						$('.actions .bullet[data-index="'+index+'"]', self).addClass('active');
					};
				}

				$(this).Swipe(data);
				swipe = $(this).data('Swipe');
			}

			if (swipe && $(this).data('carouselActions')) {
				$(this).append('<div class="actions"><button class="left-action">&lt;</button><button class="right-action">&gt;</button></div>');
				$(this).on('click', '.actions button.left-action', function(e) {
					e.preventDefault();
					swipe.prev();
				});
				$(this).on('click', '.actions button.right-action', function(e) {
					e.preventDefault();
					swipe.next();
				});
			}

			if (swipe && $(this).data('carouselBullets')) {
				var $actions = $(this).find('.actions');
				if (!$actions.size()) {
					$actions = $('<div class="actions" />');
					$actions.appendTo($(this));
				}

				for(var i = 0; i < swipe.getNumSlides(); i++) {
					$actions.append('<span class="bullet'+(i == 0 ? ' active' : '')+'" data-index="'+i+'"></span>');
				}
			}
		});
	};

	return {
		mobile : setup,
		desktop : setup,
		tablet : setup
	};
}());