var Megaleilao = {
	document : window.document,
	mobile : false,
	tablet : false,
	responsive : true,
	__lastExecutedBehavior : 'loading',
	behavior : {
	},
	requests : [],
	xhr : null,
	getDocHeight: function() {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
	executeBehavior : function(behavior, changeBehavior) {
		if (behavior === this.__lastExecutedBehavior) {
			return;
		}

		if (this.behavior) {
			$.each(this.behavior, function(i) {
				if (this[behavior]) {
					console.debug('Executando comportamento: "'+behavior+'"."' + i + '"');
					this[behavior]();
				}
			});
		}

		if (changeBehavior) {
			this.__lastExecutedBehavior = behavior;
		}
	},
	bindEvents : function() {
		$(window).resize(function() {
			if (Megaleilao.responsive) {
				var isMobile = window.matchMedia("only screen and (max-width: 767px)"),
					isTablet = window.matchMedia("only screen and (min-width: 768px) and (max-width: 991px)");

				Megaleilao.mobile = isMobile.matches;
				Megaleilao.tablet = isTablet.matches;

				if (Megaleilao.mobile) {
					Megaleilao.executeBehavior('mobile', true);
				} else if (Megaleilao.tablet) {
					Megaleilao.executeBehavior('tablet', true);
				} else {
					Megaleilao.executeBehavior('desktop', true);
				}
			}
		}).trigger('resize');
	},
	animateScroll : function(element, wrapper) {
		var $element = $(element), 
			offset = false;

		if ($element.size()) {
			offset = $(element).offset();
		}

		if (!offset) {
			return; //do nothing if element doesn't exists
		}

		if (!wrapper) {
			wrapper = 'html, body';
		}

		$(wrapper).animate({
			scrollTop: offset.top
		}, 500);
	},
	startCountdown : function(wrapper) {
		$('[data-countdown]', wrapper).each(function() {
			var self = this;
			$(self).countdown({
				until  : $(self).data('countdown'),
				layout : '{d<}{dn}{dl}{d>} {hn}{hl} {mn}{ml} {sn}{sl}',
				format : 'dHMS'
			});
		});
	},
	__validateForm : function($form) {
		var error = false, message = '',
			$required = $form.find('[required]');

		if ($required.size()) {
			var title = $form.data('validationTitle');
			
			message = 'Os campos marcados precisam de atenção:<br/>';

			$required.each(function() {
				if (!$(this).val()) {
					error = true;
					message += $(this).data('validation') + '<br />';
					$(this).addClass('validation-invalid');

					if ($(this).data('fauxselect')) {
						$(this).data('fauxselect').parent().addClass('validation-invalid');
					}

					$(this).on('change keyup blur', function() {
						if ($(this).val() != '') {
							$(this).removeClass('validation-invalid');
							if ($(this).data('fauxselect')) {
								$(this).data('fauxselect').parent().removeClass('validation-invalid');
							}

							Megaleilao.__validateForm($form);
						}
					});

					
				}
			});
		}


		if (error) {
			$form.find('[type="submit"]').prop('disabled', true)
										 .attr('disabled', true);
		} else {
			$form.find('[type="submit"]').prop('disabled', false)
										 .attr('disabled', false);
		}

		return [error, title, message];
	},
	__ajaxControl : function(e) {
		e.stopPropagation();
		e.preventDefault();

		var $self = $(this);

		if (e.type == 'change') {
			$self = $(this).closest('form');
			// if (Megaleilao.xhr) {
			// 	Megaleilao.xhr.abort();
			// }
		}

		var data = {}, url, method = 'get', type = $self.data('ajax');

		if ($self.find('[type="submit"]').prop('disabled')) {
			return false;
		}
		if ($self.is('form')) {
			data   = $self.serialize();
			url    = $self.attr('action');
			method = $self.attr('method'),
			error  = false;

			var validation = Megaleilao.__validateForm($self);
			error = validation[0];

			if (error && Megaleilao.Notification) {
				var message = {
					titulo_mensagem : validation[1],
					mensagem : validation[2] || ''
				};
				if (!Megaleilao.mobile) {
					Megaleilao.Notification.addNotification(message, undefined, undefined, false);
				}
				Megaleilao.Notification.setError(message);
				Megaleilao.Notification.showLastNotification();

				return false;
			}
		}

		if (!url) {
			url = $self.data('info');
			type = "modal";
		}

		if (!url) {
			url = $self.attr('href');
		}

		switch(method) {
			case 'get' :
			case 'post' :
			break;
			default :
				console.warn('Method not supported');
				return false;
		}

		var options = {
			url : url,
			data : data,
			type : method,
			global : true
			// context : $self
		};

		Megaleilao.xhr = $.ajax(options).done(function(content) {
			var contentHolder = '#content';
			if ($self.data('ajaxContent')) {
				contentHolder = $self.data('ajaxContent');
			}

			if ($self.data('analytics')) {
				switch($self.data('analytics')) {
					case 'bid': {
						var value = parseInt($self.find('#input-lance').val());
						var user = $self.data('currentUser');
						var title = $('#offer-info-data > h3').text();
						try {
							if (user && _gaq !== undefined) {
								_gaq.push(['_trackEvent', 'lance', title, user, value]);
							}
						} catch(e) {}
					}
				}
			}

			switch(type) {
				case 'load' :
					if (typeof content == 'string') {
						$(contentHolder).html(content);
						var messages = null;
						if ($(contentHolder + ' > .messages').size()) {
							messages = $(contentHolder + ' > .messages').detach();
						}

						if ($('.messages').size()) {
							$('.messages').replaceWith(messages);
						} else {
							$(contentHolder).before(messages);
						}
					} else {
						$(contentHolder).html(content.content);
						
						if (content.title) {
							$('title').html(content.title);
						}

						if (content.breadcrumbs) {
							$breadcrumbs = $('#content .breadcrumbs');
							if (!$breadcrumbs.size()) {
								$(contentHolder).prepend(content.breadcrumbs);
							} else {
								$breadcrumbs.replaceWith(content.breadcrumbs);
							}
						}
					}

					var $recaptcha = $('#content .g-recaptcha');
					if ($recaptcha.size() && grecaptcha) {
						grecaptcha.render($recaptcha.get(0), { sitekey : $recaptcha.attr('data-sitekey') });
					}
				break;
				case 'replace' :
				case 'modal' :
					if (typeof content == 'string') {
						if (Megaleilao.Modal.isOpen() || type == 'modal') {
							Megaleilao.Modal.holdBackContent();
							Megaleilao.Modal.show(content);
						} else {
							$('#content').html(content);
						}
					} else {
						if ($self.data('ajaxTemplate') && $self.data('ajaxContent')) {
							var tmpl = $.templates($self.data('ajaxTemplate'));
							$(contentHolder).html(tmpl.render(content));
						} else if (content.message) {
							var error = content.message.error || false;
							var messages = content.message.messages;

							if (content.reloadcaptcha && grecaptcha) {
								grecaptcha.reset();
							}

							var $alert = $('form > .form-messages');
							if (!$alert.find('.alert').size()) {
								$alert.remove();
								$alert = null;
							}
							if (!$alert || !$alert.size()) {
								$alert = $('<div class="form-messages col-xs-12 col-sm-12 col-md-12 col-lg-12"><div class="alert alert-danger alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button></div></div>');
								$('form', contentHolder).prepend($alert);
							} else {
								$('.alert ul', $alert).remove();
							}

							$alert.find('.alert').append('<ul><li>'+messages.join('</li><li>')+'</li></ul>');
						} else if (content.content) {
							$(contentHolder).html(content.content);
						} else if (content.redirect === true) {
							Megaleilao.Modal.hide();
						} else if (content.logged) {
							window.location = $('base').attr('href');
						} else if (content.redirect) {
							var url = $('base').attr('href') + content.redirect;
							if (Megaleilao.Modal.isOpen()) {
								$.ajax({ url : url, type : 'GET' }).done(function(content) {
									Megaleilao.Notification.setNormal();
									Megaleilao.Notification.close();
									Megaleilao.Modal.show(content);
								});
							} else {
								window.location = url;
							}
						}

						if (content.title) {
							$('title').html(content.title);
						}

						if (content.breadcrumbs) {
							$breadcrumbs = $('.breadcrumbs', contentHolder);
							if (!$breadcrumbs.size()) {
								$(contentHolder).prepend(content.breadcrumbs);
							} else {
								$breadcrumbs.replaceWith(content.breadcrumbs);
							}
						}
					}
				break;
				default :
					Megaleilao.Notification.close();
				break;
			}
			var $messages = $('.alert, .messages');
			if ($messages.size()) {
				Megaleilao.animateScroll('.alert, .messages');
			}
		}).fail(function(xhr) {
			var content = xhr.responseJSON || xhr.responseText;

			if (!content) {
				return;
			}

			switch(type) {
				case 'load' :
					if (typeof content == 'string') {
						$('#content').html(content);
					} else {
						$('#content').html(content.content);
					}
				break;
				case 'replace' :
					if (typeof content == 'string') {
						Megaleilao.Modal.holdBackContent();
						Megaleilao.Modal.show(content);
					} else {
						if (content.message) {
							var error = content.message.error || false;
							var messages = content.message.messages;
							var $alert = $('form > .form-messages');

							if (content.reloadcaptcha && grecaptcha) {
								grecaptcha.reset();
							}

							if (!$alert.find('.alert').size()) {
								$alert.remove();
								$alert = null;
							}
							if (!$alert || !$alert.size()) {
								$alert = $('<div class="form-messages col-xs-12 col-sm-12 col-md-12 col-lg-12"><div class="alert alert-danger alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button></div></div>');
								$('#content form').prepend($alert);
							} else {
								$('.alert ul', $alert).remove();
							}

							$alert.find('.alert').append('<ul><li>'+messages.join('</li><li>')+'</li></ul>');
						} else if (content.logged) {
							window.location = $('base').attr('href');
						} else if (content.redirect) {
							var url = $('base').attr('href') + content.redirect;
							if (Megaleilao.Modal.isOpen()) {
								$.ajax({ url : url, type : 'GET' }).done(function(content) {
									Megaleilao.Notification.setNormal();
									Megaleilao.Notification.close();
									Megaleilao.Modal.show(content);
								});
							} else {
								window.location = url;
							}
						}
					}
				break;
			}

			var $messages = $('.alert, .messages');
			if ($messages.size()) {
				Megaleilao.animateScroll('.alert, .messages');
			}
		}).always(function() {
			$self.find('[type="submit"]').prop('disabled', false)
										 .attr('disabled', false);
		});

	},
	startAjaxControl : function() {
		$(document).on('submit', 'form[data-ajax]', this.__ajaxControl);
		$(document).on('change', 'form[data-ajax="load"] input[type="checkbox"]', this.__ajaxControl);
		$(document).on('click',  'a[data-ajax], [data-info]', this.__ajaxControl);
	},

	addMasks : function(event, jqXHR, ajaxOptions) {
		$.mask.masks['bid'] = {
			mask: '99,9999999999999',
			type: 'reverse',
			defaultValue: '000'
		};
		$.mask.masks['card'] = {
			mask: '9999 9999 9999 9999'
		};
		$.mask.masks['cvv'] = {
			mask: '99999'
		};
		$.mask.masks['phone'] = {
			mask: '(99) 9999-99999'
		};
		$.mask.masks['phone9digit'] = {
			mask: '(99) 99999-9999'
		};

		var context = event ? $(event.target) : null;
		$('[data-mask]', context).each(function() {
			var maskType = $(this).attr('data-mask');
			switch(maskType) {
				case 'bid' :
					$(this).setMask('bid');
				break;
				default :
					$(this).setMask();
			}
		});
	},

	/** http://phpjs.org/functions/number_format/ */
	number_format : function(number, decimals, dec_point, thousands_sep) {
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return '' + (Math.round(n * k) / k).toFixed(prec);
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	},

	objSize : function(obj) {
		var count = 0;
		if (typeof obj == "object") {
			if (Object.keys) {
				count = Object.keys(obj).length;
			} else if (window._) {
				count = _.keys(obj).length;
			} else if (window.$) {
				count = $.map(obj, function() { return 1; }).length;
			} else {
				for (var key in obj) if (obj.hasOwnProperty(key)) count++;
			}
		}
		return count;
	}
};

jQuery(function($) {
	Megaleilao.executeBehavior('load');
	Megaleilao.bindEvents();
	Megaleilao.startCountdown();
	Megaleilao.startAjaxControl();
	Megaleilao.addMasks();

	$(document).ajaxComplete(Megaleilao.addMasks);

	$(document).ajaxComplete(function(event, jqXHR, ajaxOptions) {
		if (!jqXHR.responseJSON) {
			Megaleilao.requests.push([ajaxOptions, jqXHR]);
		}

		Megaleilao.startCountdown();
	});
});