Megaleilao.behavior.menu = (function() {
	var $menu, $logo, baseImg,
		removeClassFunc = function() {
			$('body').removeClass('desktop').removeClass('mobile').removeClass('tablet');
			$menu.removeClass('tablet-menu').removeClass('mobile-menu').removeClass('tablet-menu').removeAttr('style');
		};
	return {
		load : function() {
			$menu = $('nav');
			$logo = $('#logo');
			baseImg = $('img', $logo).attr('src');
		},
		desktop : function() {
			removeClassFunc();
			$('body').addClass('desktop');
			$menu.addClass('desktop-menu');
			$('img', $logo).attr('src', baseImg.replace('logo_mobile', 'logo'));
		},
		tablet : function() {
			Megaleilao.behavior.menu.mobile();
			$('body').removeClass('mobile').addClass('tablet');
			$('img', $logo).attr('src', baseImg.replace('logo.', 'logo_mobile.'));
		},
		mobile : function() {
			removeClassFunc();
			$('body').addClass('mobile');
			$menu.addClass('mobile-menu');
			$menu.css({ left : $(window).width() * -1 });
			$('img', $logo).attr('src', baseImg.replace('logo.', 'logo_mobile.'));
		},
	}
}());