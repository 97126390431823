Megaleilao.behavior.auctionData = (function() {
	var auctionData = {
		wrapper : null,

		'modal.hide' : function() {
			$('.zoomContainer').remove();
		},

		load : function() {
			$(document)
				// .on('click', '[data-info]', this.dataInfoClick)
				.on('change', '.pcs-fauxselect > select', function() {
					var value = $(this).val(),
						$self = $(this).siblings('[data-select]'),
						$fauxSelectStructure = $(this).siblings('div[role="select"]'),
						actualField = $self.data('select');

					$self.find('[dava-value="'+value+'"]').click();
					$fauxSelectStructure.find('.active').removeClass('active');
					$fauxSelectStructure.find('[data-value="'+value+'"]').addClass('active');
					$(actualField).val(value).trigger('change');
				}).
				on('click', '.offer-info-image img', function(event) {
					event.preventDefault();
					var swipe = $('#offer-info-more-views').data('Swipe');
					if (!swipe) {
						swipe = $('#offer-info-more-views').Swipe({
							transitionEnd : function(index, elem) {
								$('#offer-info-more-views .bullet.active').removeClass('active');
								$('#offer-info-more-views .bullet[data-index="'+index+'"]').addClass('active');
							}
						}).data('Swipe');
						$('#offer-info-more-views').append('<div class="actions"><button class="left-action">&lt;</button><button class="right-action">&gt;</button></div>');

						for(var i = 0; i < swipe.getNumSlides(); i++) {
							$('#offer-info-more-views .right-action').before('<span class="bullet'+(i == 0 ? ' active' : '')+'" data-index="'+i+'"></span>');
						}

						$('#offer-info-more-views .actions button.left-action').on('click', function(e) {
							e.preventDefault();
							swipe.prev();
						});
						$('#offer-info-more-views .actions button.right-action').on('click', function(e) {
							e.preventDefault();
							swipe.next();
						});
					}

					$('body').addClass('content-lock');
					Megaleilao.Notification.show();
					Megaleilao.Notification.clearActions();
					if (Megaleilao.Modal.isOpen()) {
						Megaleilao.Modal.holdBackContent();
						Megaleilao.Notification.addAction('back', function() {
							Megaleilao.Modal.goBackContent();
							Megaleilao.Notification.clearActions();
							Megaleilao.Notification.addAction('back', function() {
								Megaleilao.Modal.hide();
								Megaleilao.Notification.hide();
								$('body').removeClass('content-lock');
							}).show();
							$('#offer-info-more-views').removeClass('active');
						});
					} else {
						Megaleilao.Notification.addAction('back', function() {
							Megaleilao.Modal.hide();
							Megaleilao.Notification.hide();
							$('body').removeClass('content-lock');
							$('#offer-info-more-views').removeClass('active');
						}).show();
					}
					$('#offer-info-more-views').addClass('active');
					Megaleilao.Notification.setTitle('Galeria de Fotos');
				}).
				on('click', '[data-switch]', function(event) {
					event.preventDefault();
					var el = $(this).data('switch');
					$(el).toggle();
					$(this).toggleClass('activated');
				});
			;
		},

		desktop : function() {
			var setupZoom = function() {
				var $zoom = $('#offer-info-images .offer-info-image img[data-zoom-image]');
				$zoom.elevateZoom({
					gallery:'offer-info-more-views',
					cursor: 'pointer', 
					galleryActiveClass: 'active', 
					imageCrossfade: true, 
					// loadingIcon: '../img/spinner.gif',
					constrainType: 'width',
					constrainSize: $('#offer-info-images .offer-info-image').width(),
					borderSize: 0,
					zoomWindowOffetx : 30
				});
			};

			var auctionDataAjaxEvent = function(event, jqXHR, ajaxOptions) {
				if (!Megaleilao.mobile && !Megaleilao.tablet) {
					var $data = $('#modal-wrapper .list-square-items[data-select] [data-value], #offer-info .list-square-items[data-select] [data-value]');
					if ($data.size() == 1 && !$data.hasClass('active')) {
						$data.click();
					}

					setupZoom();
				}
			};

			$('div.panel-search-refiner:not(:first) > ul').each(function() {
				var $lis = $('li:gt(4)', this);
				if ($lis.size()) {
					$lis.hide();
					$(this).append('<li class="seemore"><a href="#" class="text-red text-right" title="+ Veja todos">+ Veja todos</a></li>');
				}
			});

			$(document).on('click', '.panel-search-refiner .seemore a', function(e) {
				e.preventDefault();
				if (!$(this).hasClass('less')) {
					$(this).closest('ul').find('li:not(.seemore)').show(300);
					$(this).text('- Veja menos').addClass('less');
				} else {
					$(this).closest('ul').find('li:gt(4):not(.seemore)').hide(300);
					$(this).text('+ Veja mais').removeClass('less');
				}
			});


			auctionDataAjaxEvent();
			$(document).ajaxComplete(auctionDataAjaxEvent);
		},

		mobile : function() {
			var setupMobile = function(wrapper) {
				Megaleilao.behavior.auctionData.setupSelect(wrapper);
				Megaleilao.behavior.auctionData.setupAuctionData(wrapper);
				Megaleilao.behavior.auctionData.setupAccordions(wrapper);
			};

			$(document).ajaxComplete(function(event, jqXHR, ajaxOptions) {
				if (Megaleilao.mobile) {
					setupMobile('#modal-wrapper');
				}
			});
			setupMobile();
		},

		setupAuctionData : function(wrapper) {
			var $bidForm = $('.offer-bid-form', wrapper);

			if (!$('[data-wrapper="accordion"]', $bidForm).size()) {
				$('.offer-bid-check-shipping').addClass('hidden-xs');
				var $accordion = $('<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" data-wrapper="accordion" data-wrapper-open="true" />');
				$('.offer-bid-raise', $bidForm).after($accordion);
				$accordion.append('<h2>Dados do Leilão</h2>');
				$('.offer-bid-next, .offer-bid-raise, .offer-bid-check-shipping, .offer-original-value', $bidForm).appendTo($accordion);

				$historyQty = $('.offer-bid-history-quantity', wrapper);
				// $historyQty.addClass('hidden');

				$('.offer-bid-history', wrapper).find('h2').append($historyQty.find('.value').clone().addClass('badge pull-right').removeClass('text-red'));
			}
		},

		setupSelect : function(wrapper) {
			$('#offer-bid-apply [data-select]', wrapper).each(function() {
				if ($(this).parent().hasClass('pcs-fauxselect')) {
					return true;
				}

				var $self = $(this),
					$select = $('<select />'),
					$fauxSelectStructure = $('<div role="select">Selecione o tamanho...</div>'),
					actualField = $self.data('select');

				$select.append('<option value="">Selecione o tamanho...</option>');

				$(this).find('[data-value]').each(function() {
					$select.append('<option value="'+$(this).data('value')+'">'+$(this).text()+'</option>');
					$fauxSelectStructure.prepend('<div role="option" data-value="'+$(this).data('value')+'">'+$(this).text()+'</div>');
				});

				$(actualField).data('fauxselect', $(this));

				$(this).parent().addClass('pcs-fauxselect').siblings('.title').addClass('hidden');
				$(this).after($fauxSelectStructure);
				$(this).after($select);
				$(this).hide();

				if ($select.find('option').size() <= 2) {
					$select.find('option[value!=""]').attr('selected', 'selected').prop('selected', true).trigger('change');
				}
			});
		},

		setupAccordions : function(wrapper) {
			$('[data-wrapper]', wrapper).each(function() {
				if (!$(this).find('.megaleilao-accordion-header').size()) {
					var $h2 = $(this).addClass('megaleilao-accordion').children('h2').clone();

					$h2.addClass('megaleilao-accordion-header').click(function(e) {
						e.preventDefault();
						$(this).siblings('.megaleilao-accordion-content').slideToggle();
					});

					$(this).children('h2').hide();
					$(this).wrapInner('<div class="megaleilao-accordion-content" />');
					if (!$(this).data('wrapperOpen')) {
						$(this).children('.megaleilao-accordion-content').slideUp();
					}
					$(this).prepend($h2);
				}
			});
		}
	};

	return auctionData;
}());
