Megaleilao.behavior.sidebar = (function() {
	var $btnOptions, $sidebar;
	return {
		load : function() {
			$sidebar = $('#sidebar-wrapper');
			$btnOptions = $('.btn-options');

			$(document).ajaxComplete(function(e, response, xhr) {
				$form = $('#attributes-filter');
				if ($sidebar && xhr.url.indexOf('ajax/search') > -1 && (Megaleilao.mobile || Megaleilao.tablet)) {
					if ($form.siblings('.btn-back').size())  {
						$form.siblings('.btn-back').text('Clique aqui e visualize os resultados da busca!').removeClass('btn-disabled');
					} else {
						$form.before($('<button class="btn btn-back btn-block">Visualize os resultados da busca!</button>').click(function() {
							if ($(this).is('.btn-disabled')) {
								return false;
							}
							$sidebar.animate({ left : $(window).width() * -1 }, 500, function() {
								$(this).removeClass('show');
					 			$('body').toggleClass('content-lock');
								Megaleilao.Notification.hide();
							});
						}));
					}
				}
			});
		},
		mobile : function() {
			$sidebar.addClass('closed sidebar-mobile');

			if (!$('.btn-options').size()) {
				$btnOptions = $('<div class="row"><div class="col-xs-12 col-md-12 col-sm-12 col-lg-12"></div></div>');
				var actions = {};
				$btnOptions.addClass('submenu-mobile');

				actions.menu = $('<a href="#menu" class="btn btn-default btn-options pull-left"><i class="glyphicon glyphicon-menu-hamburger"></i> Menu</a>').on('click', function(e) {
					e.preventDefault();
					$('.mobile-menu').addClass('show').animate({ left : 0 }, 500, function() {
						Megaleilao.Notification.clearActions().addAction('back', function() {
							$('.mobile-menu').animate({ left : $(window).width() * -1 }, 500, function() {
								$(this).removeClass('show');
				 				$('body').toggleClass('content-lock');
								Megaleilao.Notification.hide();
							});
						});
						Megaleilao.Notification.setTitle('Menu');
					});
					Megaleilao.Notification.show();
				 	$('body').toggleClass('content-lock');
				});

				actions.search = $('<a href="#refine-search" class="btn btn-default btn-options btn-search pull-left"><i class="glyphicon glyphicon-filter"></i> Filtrar</a>').on('click', function(e) {
					e.preventDefault();
					$sidebar.addClass('show').animate({ left : 0 }, 500, function() {
						Megaleilao.Notification.clearActions().addAction('back', function() {
							$sidebar.animate({ left : $(window).width() * -1 }, 500, function() {
								$(this).removeClass('show');
					 			$('body').toggleClass('content-lock');
								Megaleilao.Notification.hide();
							});
						});
						Megaleilao.Notification.setTitle('Filtrar Leilões');
					});
					Megaleilao.Notification.show();
				 	$('body').toggleClass('content-lock');
				});

				actions.cart = $('#navbar .cart-holder').addClass('pull-right');

				var $wrapperBefore = $('.messages');
				if (!$wrapperBefore.size()) {
					$wrapperBefore = $('#content-wrapper');
				}
				$wrapperBefore.before($btnOptions);

				var $holder = $btnOptions.children('div');
				$.each(actions, function() {
					$holder.append(this);
				});
			}

			if (!$('.btn-back').size()) {
				$sidebar.find('#attributes-filter').before($('<button class="btn btn-back btn-block btn-disabled">Selecione algumas opções para fazer uma busca</button>').click(function() {
					if ($(this).is('.btn-disabled')) {
						return false;
					}
					$sidebar.animate({ left : $(window).width() * -1 }, 500, function() {
						$(this).removeClass('show');
			 			$('body').toggleClass('content-lock');
						Megaleilao.Notification.hide();
					});
				}));
			}

			$sidebar.find('.panel-search-refiner .panel-heading').off('click touch').on('click touch', function(e) {
			 	e.preventDefault();
			 	e.stopImmediatePropagation();

			 	$(this).parent().siblings('.panel').find('.list-group').slideUp().removeClass('visible');
			 	$(this).siblings('.list-group').stop().toggleClass('visible').slideToggle();
			});

			$sidebar.find('.panel-search-refiner .list-group-item').off('click touch').on('click touch', function(e) {
			 	e.preventDefault();
			 	e.stopPropagation();
			 	e.stopImmediatePropagation();

			 	$(this).toggleClass('checked');

			 	var $panel = $(this).closest('.panel'),
			 		$header = $panel.find('.panel-heading h6'),
			 		$placeholder = $header.find('span.selected');

			 	if (!$placeholder.size()) {
			 		$header.append('<span class="selected" />');
			 		$placeholder = $header.find('.selected');
			 	}

			 	if ($(this).hasClass('checked')) {
			 		$(this).find('.checkbox input').addClass('active').attr('checked', 'checked').prop('checked', true);
			 	} else {
			 		$(this).find('.checkbox input').removeClass('active').removeAttr('checked').prop('checked', false);
			 	}

			 	$(this).find('.checkbox input').change();

			 	$placeholder.text($panel.find('.checked').map(function() {
			 		return $(this).text().trim();
			 	}).get().join(', '));
			});
		},
		tablet : function() {
			Megaleilao.behavior.sidebar.mobile();
		},
		desktop : function() {
			$sidebar.find('#attributes-filter').siblings('.btn-back').remove();
			$('.cart-holder').removeClass('pull-right').appendTo('#navbar');
			$sidebar.removeClass('closed').removeClass('opened').removeClass('sidebar-mobile');
			$sidebar.find('.panel-search-refiner .list-group-item').off('click touch');
			$sidebar.find('.panel-search-refiner .panel-heading').off('click touch');
			$sidebar.find('.btn-filter').hide();
			$sidebar.removeAttr('style');
			$btnOptions.remove();
		}
	}
}());
